import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：爆款案例】
 * 生成日期：2024/03/13 16:12:13
 * 生成路径: src/api/popular_case.js
 * 生成人：超级管理员
 * 需要在文件src/api/index.js 引入当前文件
 * import popular_case from './popular_case'
 * export default {
 * ...
 * 物流信息订阅
 *    ...popular_case,
 * }
 */

/**
 * @description 获取列表
 * @param {*} data
 * @returns
 */
export function getPopularCaseList(data) {
  return request.post('/popular_case/list', data)
}

/**
 * @description 保存
 * @param {*} data
 * @returns
 */
export function savePopularCase(data) {
  return request.post('/popular_case/store', data)
}

/**
 * @description 获取详情
 * @param {*} id
 * @returns
 */
export function getPopularCaseInfo(id) {
  let data = { id: id }
  return request.post('/popular_case/get_info', data)
}

/**
 * @description 根据id删除单条信息
 * @param ids
 */
export function deletePopularCase(ids) {
  let data = { ids }
  return request.post('/popular_case/delete', data)
}

/**
 * 导入
 * @param data
 * @returns {Promise}
 */
export function importPopularCases(data) {
  return request.post('/popular_case/import', data)
}

/**
 * 导出
 * @param data
 * @returns {Promise}
 */
export function exportPopularCases(data) {
  return request.postBlob('/popular_case/export', data)
}

/**
 * 异步导出
 * @param data
 * @returns {*}
 */
export function exportAsyncPopularCases(data) {
  return request.post('/popular_case/async_export', data)
}

/**
 * 下拉列表
 * @param data
 * @returns {*}
 */
export function optionsPopularCases(data) {
  return request.post('/popular_case/options', data)
}

/**
 * 下拉列表-分类
 * @param data
 * @returns {*}
 */
export function optionsPopularCasesCategory(data) {
  return request.post('/popular_cases_category/options', data)
}


/**
 * 爆款案例分类列表
 * @param data
 * @returns {*}
 */
export function popularCasesCategoryList(data) {
  return request.post('/popular_cases_category/list', data)
}

/**
 * 爆款案例分类保存
 * @param data
 * @returns {*}
 */
export function popularCasesCategorySave(data) {
  return request.post('/popular_cases_category/store', data)
}

/**
 * 爆款案例分类删除
 * @param data
 * @returns {*}
 */
export function popularCasesCategoryDelete(data) {
  return request.post('/popular_cases_category/delete', data)
}

/**
 *
 * @param data
 * @returns {*}
 */
export function boardGvm(data) {
  return request.post('/popular_cases_category/board_gvm', data)
}

/**
 *
 * @param data
 * @returns {*}
 */
export function boardGvmDetail(data) {
  return request.post('/popular_cases_category/board_gvm_detail', data)
}

/**
 *
 * @param data
 * @returns {*}
 */
export function boardGvmDetailThe(data) {
  return request.post('/popular_cases_category/board_gvm_detail_the', data)
}
